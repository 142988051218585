
import { mapState, mapMutations, mapActions } from 'vuex'
import AddPaymentButton from '@pleinair/bigcolony-vue/components/cart/checkout/elements/AddPaymentButton.vue'
import GiftCardsStored from './GiftCardsStored.vue'
import GiftCardsForm from './GiftCardsForm.vue'
import GiftCardsNew from './GiftCardsNew.vue'

export default {
  name: 'GiftCards',
  components: {
    AddPaymentButton,
    GiftCardsStored,
    GiftCardsForm,
    GiftCardsNew
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    labels: {
      type: Boolean,
      default: false
    },
    pinLabel: {
      type: String,
    },
    pinPlaceholder: {
      type: String,
    },
    errorMessage: {
      type: String,
    }
  },
  computed: {
    ...mapState({
      billing: state => state.checkout.billing,
      paymentType: state => state.checkout.paymentType,
      giftcards: state => state.checkout.giftcards
    }),
    enabled() {
      return !this.disabled && this.billing.find(b => b.type === this.type) ? true : false
    },
    oldCards() {
      let accounts = []
      if(this.enabled) {
        const giftcards = this.billing.find(b => b.type === this.type).accounts
        giftcards.map(g => {
          if(g.balance > 0) {
            accounts.push(g)
          } else {
            // AUTO DELETE
            this.deleteCard(g.id)
          }
        })
      }
      return accounts
    }
  },
  data() {
    return {
      type: 'giftcard',
      newCards: [],
      showForm: false
    }
  },
  methods: {
    ...mapActions({
      applyGiftcard: 'checkout/applyGiftcard',
      deleteCard: 'checkout/removePayment',
    }),
    ...mapMutations({
      setGiftcards: 'checkout/setGiftcards'
    }),
    toggleGiftCardForm() {
      this.showForm = !this.showForm
    },
    addNewCard(card) {
      this.$utils.log('addNewCard')
      this.newCards.push(card)
      this.showForm = false
      this.toggleCard(card)
    },
    isSelected(card) {
      if(card.id) {
        return this.giftcards.find(g => g.id === card.id) ? true : false
      } else {
        return this.giftcards.find(g => g.number === card.number) ? true : false
      }
    },
    toggleCard(card) {
      if (this.giftcards.length > 0 && card.id === this.giftcards[0].id) {
        this.setGiftcards([])
      } else {
        this.setGiftcards([])
        this.applyGiftcard(card)
      }
    }
  }
}
