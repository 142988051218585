
import { mapMutations, mapActions } from 'vuex'
import LogoAmex from '@pleinair/bigcolony-vue/components/_globals/vectors/LogoAmex.vue'
import LogoDiscover from '@pleinair/bigcolony-vue/components/_globals/vectors/LogoDiscover.vue'
import LogoMasterCard from '@pleinair/bigcolony-vue/components/_globals/vectors/LogoMasterCard.vue'
import LogoVisa from '@pleinair/bigcolony-vue/components/_globals/vectors/LogoVisa.vue'
import PaymentRadioOn from '../globals/Icon/PaymentRadioOn.vue'
import PaymentRadioOff from '../globals/Icon/PaymentRadioOff.vue'

export default {
  name: 'SavedCreditCard',
  props: {
    account: {
      type: Object,
      default: () => {}
    },
    selected: {
      type: Boolean,
      default: false
    },
    removeEnabled: {
      type: Boolean,
      default: false
    },
    onSelect: {
      type: Function,
      default: () => {}
    }
  },
  components: {
    LogoAmex,
    LogoDiscover,
    LogoMasterCard,
    LogoVisa,
    PaymentRadioOn,
    PaymentRadioOff
  },
  computed: {
    logo() {
      if (this.account && this.account.type) {
        switch(this.account.type.toLowerCase()) {
          case 'amex':
          case 'american express':
            return 'LogoAmex'
          case 'discover':
            return 'LogoDiscover'
          case 'mastercard':
            return 'LogoMasterCard'
          case 'visa':
            return 'LogoVisa'
          default:
            return null
        }
      }

      return null
    }
  },
  methods: {
    ...mapMutations([ 'setLoading' ]),
    ...mapActions({
      deletePayment: 'members/deletePayments'
    }),
    handleSelect() {
      this.onSelect(this.account)
    },
    removePayment(account) {
      this.setLoading(true)
      this.deletePayment(account.id)
      this.setLoading(false)
    }
  }
}
