
import { mapState, mapActions, mapMutations } from 'vuex'

import Icon from '@pleinair/bigcolony-vue/components/common/Icon/index.vue'

export default {
  name: 'CartProduct',
  components: {
    Icon,
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    placement: {
      type: String,
      default: 'menu',
      validator: (value) => {
        return ['menu', 'catering', 'order'].includes(value)
      }
    },
    position: {
      type: Number,
      default: 0
    },
    config: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState([ 'location', 'cart', 'menu', 'appInfo' ]),
    ...mapState({
      rewards: state => state.checkout.rewards,
    }),
    filterUtensils() {
      if (this.menu?.singles?.length) {
        return this.menu.singles[0].products.find(product => product.name === 'Utensils')
      }
      return null;
    },
    placeholderImage() {
      return this.appInfo.order['placeholder_image']
    },
    imgSrc() {
      if (this.data.images && this.data.images['marketplace-product']) {
        return this.data.images['marketplace-product'].url
      }
      if (this.data.images && this.data.images.default) {
        return this.data.images.default.url
      }

      if (this.menu) {
        for (const category of this.menu.categories) {
          for (const product of category.products) {
            if (this.data.product_id === product.product_id || this.data.name === product.name) {
              if (product.images && product.images['marketplace-product']) {
                return product.images['marketplace-product'].url
              }
              if (product.images && product.images.default) {
                return product.images.default.url
              }
              // didnt find an image...
              break
            }
          }
        }
      }

      return ''
    },
    cost() {
      if (!this.data.cost) return '$0.00'
      return this.$utils.currency(this.data.cost.total)
    },
    choices() {
      if (!this.data.choices) return ''
      // TODO: add calories when we have the data

      return this.data.choices
        .reduce((acc, curr) => {
          acc.push(curr.name)
          return acc
        }, [])
        .join(' | ')
    },
    recipient() {
      if (!this.data.recipient) return ''
      return `Made For: ${this.data.recipient}`
    },
    isPDP() {
      return this.$route.fullPath.includes('product')
    },
    productInMenu() {
      return this.menu?.categories
        .map(category => category.products)
        .flat()
        .find(product => product.product_id === this.data.product_id)
    },
    baseCalories() {
      return this.productInMenu?.nutrition?.calories?.base || ''
    },
    maxCalories() {
      return this.productInMenu?.nutrition?.calories?.max || ''
    },
    calories() {
      const caloriesLabel = this.config?.caloriesLabel || 'Cal'

      if (this.baseCalories && this.maxCalories) {
        return `${this.baseCalories} - ${this.maxCalories} ${caloriesLabel}`
      } else if (this.baseCalories) {
        return `${this.baseCalories} ${caloriesLabel}`
      } else if (this.maxCalories) {
        return `${this.maxCalories} Max ${caloriesLabel}`
      } else {
        return ''
      }
    },
  },
  methods: {
    ...mapMutations(['setLoading', 'setErrors']),
    ...mapActions(['hideCart']),
    ...mapActions({
      updateProduct: 'product/updateProduct',
      removeProduct: 'product/removeProduct',
      removeCartReward: 'checkout/removeCartReward',
      validateCart: 'checkout/validateCart',
    }),
    async decrease(p) {
      if(p.quantity > 1) {
        const updatedProd = {
          id: p.id,
          quantity: p.quantity - 1,
          choices: []
        }
        p.choices.map(c => {
          updatedProd.choices.push({
            id: c.option_id,
            quantity: 1
          })
        })
        await this.updateProduct(updatedProd)
      }
    },
    async increase(p) {
      if(p.quantity < 99) {
        const updatedProd = {
          id: p.id,
          quantity: p.quantity + 1,
          choices: []
        }
        p.choices.map(c => {
          updatedProd.choices.push({
            id: c.option_id,
            quantity: 1
          })
        })
        await this.updateProduct(updatedProd)
      }
    },
    async removeItem(p) {
      console.log(p)
      await this.removeProduct(p)

      if (this.cart.products.length == 0) {
        // Find and Remove applied rewards
        this.rewards.map(reward => {
          if(reward.applied) {
            this.removeCartReward(reward.id)
          }
        });

        this.hideCart()
        document.body.style.overflow = 'auto'
      
        // Redirects to menu
        this.$router.push(`/menu/${this.location.menu_id}`)
      }
    },
    editLink(p) {
      document.body.style.overflow = 'auto'
      this.hideCart();
      const productEditPath = `/menu/${this.cart.menu_id}/product/${p.product_id}?edit=${p.product_id}&position=${this.position}`
      
      // check if this product is already in edit
      if (this.$route.fullPath === productEditPath) {
        return
      }
      // check if PDP is open to refresh the page
      if (this.isPDP) {
        this.$router.replace(productEditPath)
        return setTimeout(() => this.$router.go(), 200)
      }
      // redirect to the PDP page
      this.$router.push(productEditPath)
    },
  }
}
