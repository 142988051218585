
import { mapState, mapActions } from 'vuex'

import AnchorCategory from '@pleinair/bigcolony-vue/components/common/AnchorCategory.vue'
import CategorySearch from '@pleinair/bigcolony-vue/components/common/CategorySearch.vue'
import SingleCategory from '@pleinair/bigcolony-vue/components/common/SingleCategory.vue'
import NoCategoryResults from '@pleinair/bigcolony-vue/components/common/NoCategoryResults.vue'
import MultibrandMenu from '@pleinair/bigcolony-vue/components/common/MultibrandMenu.vue'
import OrderCard from '@pleinair/bigcolony-vue/components/common/OrderCard.vue'
// import Icon from '@pleinair/bigcolony-vue/components/common/Icon/index.vue'
import CustomButton from '@/components/common/CustomButton.vue'
export default {
  name: 'Menu',
  components: {
    AnchorCategory,
    SingleCategory,
    CategorySearch,
    NoCategoryResults,
    MultibrandMenu,
    OrderCard,
    // Icon,
    CustomButton,
  },
  data() {
    return {
      selectedCategory: '',
    }
  },
  props: {
    placement: {
      type: String,
      default: 'menu',
      validator: (value) => {
        return ['menu', 'catering', 'order'].includes(value)
      }
    },
    config: Object,
  },
  mounted() {
    this.filterMenu({ searchTerm: '', placement: this.placement })
    window.addEventListener('scroll', this.debounce)
    this.handleScroll();
    console.log("ORDER HISTORY", this.orderHistory)
  },
  destroyed () {
    window.removeEventListener('scroll', this.debounce)
  },
  computed: {
    ...mapState([ 'location', 'menu', 'filteredMenu', 'searchedMenu', 'cart', 'appInfo', 'user', 'orderHistory', 'ActiveSuperCategory' ]),
    categories() {
      if (this.filteredMenu && this.filteredMenu.length > 0) {
        return this.filteredMenu
      }

      if (this.searchedMenu) return []

      return this.menu.categories
    },
    lastOrders() {
      if(this.orderHistory) {
        return this.orderHistory.slice(0, 3);
      }
    },
    multibrandCategories() {
      if (this.ActiveSuperCategory && this.ActiveSuperCategory !== this.appInfo?.name) {
        return this.categories.filter(c => c.super_category === this.ActiveSuperCategory)
      }
      return this.categories.filter(c => c.super_category === this.appInfo?.name || !c.super_category)
    },
    availableCategories() {
      if (this.appInfo?.menu?.is_multibrand) {
        return this.multibrandCategories
      }
      return this.categories
    },
  },
  methods: {
    ...mapActions([ 'filterMenu', 'getOrderHistory' ]),
    debounce(callback) {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }

      this.timeout = setTimeout(() => {
        this.handleScroll()
      }, 200)
    },
    handleScroll() {
      // get all category sections
      const $menuCategories = document.getElementsByClassName('menu-category')
      if (!$menuCategories) return

      // get the category that is in view
      const mostVisibleEl = this.$utils.getMostVisibleElement($menuCategories)
      if (!mostVisibleEl) return

      // assign the category id to style the anchor as --active
      this.selectedCategory = mostVisibleEl.id

      // get the active anchor element
      const anchorId = `anchor-${mostVisibleEl.id}`
      const $anchor = document.getElementById(anchorId)
      if (!$anchor) return

      // get the active anchor position
      const anchorPosition = this.$utils.getAbsoluteBoundingRect($anchor)
      const $menuAnchors = document.getElementById('menu-anchors')
      if (!$menuAnchors || !anchorPosition) return

      // scroll the anchors section to the active anchor
      $menuAnchors.scrollLeft = anchorPosition.left
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  },
  async beforeMount() {
    if(this.user && this.appInfo?.menu?.show_recent_orders) {
      await this.getOrderHistory();
    }
  },
}
