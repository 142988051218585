
  import { mapState, mapMutations, mapActions } from 'vuex'
  
  import AddPaymentButton from '@pleinair/bigcolony-vue/components/cart/checkout/elements/AddPaymentButton.vue'
  import SavedCreditCard from './SavedCreditCard.vue'

  export default {
    name: 'CreditCards',
    components: {
      AddPaymentButton,
      SavedCreditCard,
    },
    props: {
      labels: Boolean,
      ccFrame: Object,
      ccID: String,
      ccStyles: Object,
    },
    data() {
      return {
        type: 'creditcard',
        typeNew: 'newcredit'
      }
    },
    computed: {
      ...mapState({
        appInfo: state => state.appInfo,
        token: state => state.token,
        billing: state => state.checkout.billing,
        paymentType: state => state.checkout.type,
        account: state => state.checkout.account
      }),
      accounts() {
        let accounts = this.billing.find(b => b.type === this.type)
        return accounts ? accounts.accounts : []
      },
      enabled() {
        return !this.disabled && this.billing.find(b => b.type === this.type) ? true : false
      },
      allowSave() {
        const billingFound = this.billing.find(b => b.type === this.type)
        if (!billingFound) {
          return false
        }

        return billingFound.save && this.token ? true : false
      },
      newPayment() {
        return this.paymentType === this.typeNew
      }
    },
    methods: {
      ...mapMutations({
        setPaymentAccount: 'checkout/setPaymentAccount'
      }),
      ...mapActions({
        setPaymentSelection: 'checkout/setPaymentSelection'
      }),
      toggleNew() {
        if(this.newPayment) {
          this.setPaymentSelection({ type: null })
        } else {
          this.setPaymentSelection({ type: this.typeNew })
        }
      },
      setAccount(a) {
        this.$utils.log('setAccount', a)
        this.setPaymentSelection({ type: 'storedpayment' })
        this.setPaymentAccount(a)
      },
      handleSubmit(payments) {
        switch(this.appInfo.payment) {
          case 'OloCcsf':
          case 'OloPay':
            this.$refs.ccform.handleSubmit(payments)
            break;
        }
      }
    }
  }
