
import { mapState, mapMutations, mapActions } from 'vuex'

import HeatMeter from './HeatMeter.vue'
import ProductRecipient from './ProductRecipient.vue'
import ProductDisclaimer from './ProductDisclaimer.vue'
import CustomProductModifier from './CustomProductModifier.vue'
import CustomProductCta from './CustomProductCta.vue'
import CustomProductDescription from './CustomProductDescription.vue'

export default {
  name: 'CustomProductView',
  components: {
    CustomProductDescription,
    CustomProductModifier,
    CustomProductCta,
    ProductRecipient,
    HeatMeter,
    ProductDisclaimer
  },
  props: {
    placement: {
      type: String,
      default: 'menu',
      validator: (value) => {
        return ['menu', 'catering', 'order'].includes(value)
      }
    }
  },
  data() {
    return {
      notfound: false,
      recipientFields: {
        id: 'instructions',
        heading: 'Made for',
        visible: true,
        starts_open: true
      },
      heatmeter_metas: [
        'heat_meter_primary',
        'heat_meter_primary_mod',
        'heat_meter_secondary',
        'heat_meter_secondary_mod'
      ],
      filtered_mods: null
    }
  },
  computed: {
    ...mapState([ 'appInfo' ]),
    ...mapState({
      cart: state => state.cart,
      product: state => state.product?.data,
      getChoicesFromCart: state => state.product.getChoicesFromCart,
      mods: state => state.product.mods,
      choices: state => state.product.choices,
    }),
    isAnyChoiceReaper() {
      return this.choices.some((m) => m.name?.toLowerCase().includes("reaper"))
    },
    isMobile() {
      return this.$mq == 'sm' || this.$mq == 'md' ? true : false
    },
    showDetails() {
      if (this.product?.name?.toLowerCase().includes('build your own')) {
        return false
      }
      return true
    },
    productImage() {
      if (this.product?.images) {
        if(this.product.images && this.product.images['marketplace-product']) {
          return this.product.images['marketplace-product'].url
        } else if(this.product.images && this.product.images.default) {
          return this.product.images.default.url
        }
      }
      return this.appInfo.order['placeholder_image']
    },
    otherMods() {
      return this.canUseHeatmeter ? this.filtered_mods?.others : this.mods
    },
    canUseHeatmeter() {
      if (!this.product?.heat_meter_layout) {
        return false
      }

      // return this.mods.some(mod => this.heatmeter_metas.some(meta => mod.meta[meta] == true))
      return this.mods.some(mod => 
        this.heatmeter_metas.some(meta => 
          mod.meta && mod.meta.hasOwnProperty(meta)
        )
      );
    },
  },
  async beforeMount() {
    this.setProduct(null)
    this.setLoading(true)
    const config = {
      method: 'get',
      url: `menus/${this.$route.params.mid}/products/${this.$route.params.pid}`,
    }
    const response = await this.sendRequest(config)
    if(response.status === 'ok') {
      this.viewProduct(response.data)
      this.setProduct(response.data)
      if (this.cart && this.$route.query && this.$route.query.edit == this.$route.params.pid) {
        this.setChoicesFromCart(false)
        const prodFound = this.cart.products.find((p, i) => {
          return p.product_id == response.data.product_id && i == this.$route.query.position
        })
        // console.log(prodFound)
        if (prodFound) {
          if (prodFound && prodFound.choices && prodFound.choices.length > 0) {
            const prodChoices = prodFound.choices.reduce((acc, curr) => {
              acc.push({
                ...curr,
                id: curr.option_id,
                choice_id: curr.option_id,
              })
              return acc
            }, [])
            this.setChoices(prodChoices)
          }
          this.setQuantity(prodFound.quantity)
          if (prodFound.instructions) {
            this.setInstructions(prodFound.instructions)
          }
          if (prodFound.recipient) {
            this.setRecipient(prodFound.recipient)
          }
          this.setIsEdit(true)
        }
      }
      await this.processMods()
    } else {
      this.notfound = true
    }
    this.setLoading(false)
  },
  methods: {
    ...mapMutations({
      setLoading: 'setLoading',
      setErrors: 'setErrors',
      setCart: 'setCart',
      setProduct: 'product/setProduct',
      setChoices: 'product/setChoices',
      setQuantity: 'product/setQuantity',
      setChoicesFromCart: 'product/setChoicesFromCart',
      setIsEdit: 'product/setIsEdit',
      setRecipient: 'product/setRecipient',
    }),
    ...mapActions({
      sendRequest: 'sendRequest',
      processMods: 'product/processMods',
      viewProduct: 'product/viewProduct',
    }),
    cleanCat() {
      let noAmp = this.product.category.replace('&', '').replace(/\s+/g, ' ')
      return noAmp.replace(/ /g,"-").toLowerCase()
    },
    returnLink() {
      this.$router.push(`/${this.placement}/${this.$route.params.mid}?category=${this.cleanCat()}`)
    },
    getFilteredMods() {
      const result = {
        has_meta: [],
        others: []
      }

      this.mods.forEach(item => {
        if (item.meta && Object.keys(item.meta).some(key => this.heatmeter_metas.includes(key))) {
          result.has_meta.push(item);
        } else {
          result.others.push(item);
        }
      })

      this.filtered_mods = result
    },
  },
  watch: {
    mods(val, oldVal) {
      if(val !== oldVal && this.canUseHeatmeter) {
        this.getFilteredMods();
      }
    }
  },
}
