
import LogoGiftCard from '@pleinair/bigcolony-vue/components/_globals/vectors/LogoGiftCard.vue'
import { mapState } from 'vuex'
import PaymentRadioOn from '../globals/Icon/PaymentRadioOn.vue'
import PaymentRadioOff from '../globals/Icon/PaymentRadioOff.vue'

export default {
  name: 'StoredPayment',
  components: {
    LogoGiftCard,
    PaymentRadioOn,
    PaymentRadioOff
  },
  props: {
    account: {
      type: Object,
      default: () => {}
    },
    selected: {
      type: Boolean,
      default: false
    },
    onSelect: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    ...mapState([ 'cart' ]),
    number() {
      return this.account.number.match(/\d{4}$/)
    },
    balance() {
      return this.$utils.currency(this.account.balance)
    },
    remainingBalance() {
      try {
        let remaining = Math.max(this.account.balance - this.cart.totals.total, 0)
        return this.$utils.currency(remaining)
      } catch(err) {
        return ''
      }
    }
  },
  methods: {
    handleSelect() {
      this.onSelect(this.account)
    }
  }
}
