
import { mapState, mapMutations, mapActions } from 'vuex'
import DefaultForm from '@pleinair/bigcolony-vue/components/_globals/forms/DefaultForm.vue'

export default {
  name: 'PayInStore',
  components: {
    DefaultForm
  },
  props: {
    labels: {
      type: Boolean,
      default: false
    },
    onSelect: {
      type: Function,
      default: () => {}
    },
    onCancel: {
      type: Function,
      default: () => {}
    },
    pinLabel: {
      type: String,
      default: 'Pin (if required)'
    },
    pinPlaceholder: {
      type: String,
      default: 'Pin (if required)'
    },
    errorMessage: {
      type: String,
      default: 'Gift card has no balance'
    }
  },
  computed: {
    ...mapState([ 'cart', 'appInfo' ]),
    enabled() {
      return !this.disabled && this.billing.find(b => b.type === this.type) ? true : false
    },
    disablePin() {
      return this.appInfo?.order?.['gift_card']?.['disable_pin']
    },
    fields() {
      const fields = []

      fields.push({
        name: 'number',
        label: 'Card Number',
        showLabel: this.labels,
        placeholder: this.labels ? '#### #### #### ####' : 'Card Number',
        type: 'number',
        inputmode: 'numeric',
        value: '',
        valid: /\d+/,
        touched: false,
        error: null,
        errors: {
          invalid: 'Please enter a valid card number'
        }
      })

      if (!this.disablePin) {
        fields.push({
          name: 'pin',
          label: this.pinLabel,
          showLabel: this.labels,
          placeholder: this.labels ? '####' : this.pinPlaceholder,
          type: 'number',
          inputmode: 'numeric',
          value: '',
          valid: /\d+/,
          touched: false,
          error: null,
          errors: {
            invalid: 'Please enter a valid card number'
          }
        })
      }

      return fields
    },
  },
  methods: {
    ...mapMutations([ 'setLoading', 'setErrors' ]),
    ...mapActions({
      sendRequest: 'sendRequest',
      trackEvent: 'trackEvent',
      applyGiftcard: 'checkout/applyGiftcard'
    }),
    async handleSubmit(data) {
      this.setLoading(true)
      const config = {
        method: 'post',
        url: `carts/${this.cart.id}/giftcard`,
        headers: {
          verify: true
        },
        data
      }
      if(this.appInfo.verify_transition) {
        config.headers.verify_transition = true
      }
      const response = await this.sendRequest(config)
      if(response.status === 'ok') {
        if(response.data.balance > 0) {
          this.trackEvent({
            name: 'add_gift_card',
            windowObject: window
          })
          let card = {
            number: data.number,
            pin: data.pin,
            balance: response.data.balance
          }
          this.applyGiftcard(card)
          this.onSelect(card)
        } else {
          this.setErrors(response.data.message)
        }
      } else {
        this.setErrors(response.message)
      }
      this.setLoading(false)
    }
  }
}
