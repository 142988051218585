
  import { mapState } from 'vuex'

  export default {
    name: 'CheckoutTotals',
    computed: {
      ...mapState([ 'cart', 'checkout' ]),
      discounts() {
        if (this.cart) {
          return this.cart.discounts.reduce((acc, curr) => {
            acc += curr.amount
            return acc
          }, 0)
        }
        return false
      },
      taxes() {
        if (this.cart) {
          return this.cart.taxes.reduce((acc, curr) => {
            acc += curr.amount
            return acc
          }, 0)
        }
        return false
      },
      paperBagFee() {
        if (this.cart) {
          const fee = this.cart.fees.find(fee => fee.label === 'Paper Bag Fee')
          return fee ? fee.amount : false
        }
        return false
      },
      displayTotal() {
        if (this.cart && this.checkout && this.checkout.giftcards.length > 0) {
          if (this.checkout.giftcards[0].balance > this.cart.totals.total) {
            return 0
          } else {
            return this.cart.totals.total - this.checkout.giftcards[0].balance
          }
        }
        return this.cart.totals.total
      },
      giftCard() {
        if (this.cart && this.checkout && this.checkout.giftcards.length > 0) {
          const giftCard = this.checkout.giftcards[0]
          return giftCard ? giftCard.balance : false
        }
        return false
      },
      giftCardBalance() {
        if (this.cart && this.checkout && this.checkout.giftcards.length > 0) {
          if (this.checkout.giftcards[0].balance > this.cart.totals.total) {
            return this.cart.totals.total
          } else {
            return this.checkout.giftcards[0].balance
          }
        }
        return 0
      },
      giftCardLastFour() {
        if (this.checkout && this.checkout.giftcards.length > 0) {
          if (this.checkout.giftcards[0].number) return this.checkout.giftcards[0].number.match(/\d{4}$/)
          else return this.checkout.giftcards[0].description.match(/\d{4}$/)
        }
        return false
      },
    },
  }
