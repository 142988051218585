
import { mapState, mapActions, mapMutations } from 'vuex'
import { DigitalWallets } from '@olo/pay';

import HouseAccounts from '@pleinair/bigcolony-vue/components/cart/checkout/elements/HouseAccounts.vue'
import PayInStore from '@pleinair/bigcolony-vue/components/cart/checkout/elements/PayInStore.vue'
import CreditCards from './CreditCards.vue'

export default {
  name: 'Payments',
  props: {
    labels: Boolean,
    disablePayInStore: Boolean,
    disableHouseAccounts: Boolean,
    ccFrame: Object,
    ccID: String,
    ccStyles: Object,
    hasApplePay: {
      show: Boolean,
      buttonType: {
        type: String,
        default: 'pay'
      }
    },
    hasGooglePay: {
      show: Boolean,
      buttonType: {
        type: String,
        default: 'pay'
      }
    }
  },
  components: {
    PayInStore,
    CreditCards,
    HouseAccounts
  },
  data() {
    return {
      digitalWallets: {},
      total: 0,
      subtotal: 0,
      discount: 0,
      delivery: 0,
      tax: 0,
      tip: 0,
      walletOptions: {},
      submitted: false
    }
  },
  async mounted() {
    if (this.ccFrame.name === 'OloPay' && ((this.applePay && this.hasApplePay?.show) || (this.googlePay && this.hasGooglePay?.show))) {
      this.digitalWallets = new DigitalWallets(process.env.GW_ENV === 'dev' ? 'development' : 'production')

      
      if(this.cart){
        this.setCartTotals()
      }
    }
  },
  computed: {
    ...mapState(['appInfo']),
    ...mapState({
      billing: state => state.checkout.billing,
      cart: state => state.cart,
      channel: state => state.channel,
      user: state => state.user,
      token: state => state.token,
      guestUser: state => state.checkout.guestUser,
    }),
    payInStoreDisabled() {
      return this.disablePayInStore || !this.billing.find(b => b.type === 'payinstore') ? true : false
    },
    houseAccountsDisabled() {
      return this.disableHouseAccounts || !this.billing.find(b => b.type === 'houseaccount') ? true : false
    },
    applePay() {
      return this.billing.find(b => b.type === 'applepay') || process.env.NODE_ENV === 'development' ? true : false
    },
    googlepay() {
      return this.billing.find(b => b.type === 'googlepay') || process.env.NODE_ENV === 'development' ? true : false
    },
    applePayButtonDisplay() {
      return process.env.NODE_ENV === 'development' && this.applePay ? 'display: initial' : null
    }
  },
  methods: {
    ...mapMutations(['setLoading', 'setErrors']),
    ...mapActions({
      gatherPayments: 'checkout/gatherPayments',
      sendRequest: 'sendRequest',
    }),
    convertToSubUnits(num) {
      //Split at decimal point if it exists
      let numParts = num.toString().split('.')
      let hasDecimals = (numParts.length > 1)
      //If decimal digits exist we get them or replace with 00
      let decimals = ''
      if (hasDecimals) {
        if (numParts[1].toString().length > 1) {
          decimals = numParts[1].toString()
        }
        else {
          decimals = numParts[1].toString() + "0"
        }
      }
      else {
        decimals = "00"
      }

      let number = numParts[0] + decimals
      return parseInt(number)
    },
    async walletCallback(paymentMethod) {
      if (paymentMethod) {
        this.$utils.log("PAYMENT", paymentMethod)
        this.digitalWallets.completePaymentEvent()
        if (this.digitalWallets.canMakePayment?.applePay) {
          paymentMethod.type = 'applepay'
        } else if (this.digitalWallets.canMakePayment?.googlePay) {
          paymentMethod.type = 'googlepay'
        } else {
          this.$utils.log("PAYMENTS", paymentMethod)
          return
        }
        if(!this.submitted) {
          this.submitted = true
          await this.submitOloPay(paymentMethod)
        }
      } else {
        this.submitted = false
        this.digitalWallets.failPaymentEvent()
      }
    },
    async submitPayment() {
      this.setLoading(true)
      const payments = await this.gatherPayments()
      const error = await this.$refs.ccform.handleSubmit(payments)

      if (error) {
        this.setErrors([error])
      }
      this.setLoading(false)
    },
    async submitOloPay(payment) {
      if (!this.cart) {
        console.error('** no cart found')
        return
      }

      this.setLoading(true)
      const config = {
        method: 'post',
        url: `carts/${this.cart.id}/submit`,
        data: {
          payments: [
            {
              type: payment.type,
              token: payment.id,
              card: {
                type: payment.card.brand,
                number: payment.card.last4,
                expire: {
                  month: payment.card.exp_month,
                  year: payment.card.exp_year
                }
              },
              address: {
                address: payment.billing_details.address.line1,
                city: payment.billing_details.address.city,
                state: payment.billing_details.address.state,
                zip: payment.billing_details.address.postal_code
              },
              amount: {
                total: this.total.toString(),
                tip: this.tip.toString()
              }
            }
          ]
        }
      }
      this.$utils.log("TOKEN", this.token)
      this.$utils.log("USER", this.user)
      this.$utils.log('GUEST USER', this.guestUser)
      if (!this.token) {
        if (this.guestUser) {
          config.data.first_name = this.guestUser.first_name
          config.data.last_name = this.guestUser.last_name
          config.data.phone = this.guestUser.phone
          config.data.email = this.guestUser.email
          config.data.create = false
        } else {
          this.setErrors(['Please enter your name, email, and phone number to checkout'])
          this.setLoading(false)
          return
        }
      }
      else {
        if (this.user) {
          config.data.first_name = this.user.first_name
          config.data.last_name = this.user.last_name
          config.data.phone = this.user.phone
          config.data.email = this.user.email
          config.data.create = false
        }
      }

      const response = await this.sendRequest(config)
      this.setLoading(false)

      if (response.status === 'ok') {
        this.$router.push(`/order/confirm/${response.data.id}`)
      } else {
        if(response.message == "This order has already been placed"){
          this.$router.push(`/order/confirm/${this.cart.id}`)
        }
        else{
          this.submitted = false
          this.setErrors([response.message])
          return
        }
      }
    },
    async submitApplePay(payment) {
      if (!this.cart) {
        console.error('** no cart found')
        return
      }

      this.setLoading(true)
      const config = {
        method: 'post',
        url: `carts/${this.cart.id}/submit`,
        data: {
          payments: [
            {
              type: 'applepay',
              applePayload: {
                _details: Object.assign(payment.token, { billingContact: { postalAddress: payment.billingContact } })
              }
            }
          ]
        }
      }
      if (!this.token) {
        if (this.user) {
          config.data.first_name = this.user.first_name
          config.data.last_name = this.user.last_name
          config.data.phone = this.user.phone
          config.data.email = this.user.email
        } else if (this.guestUser) {
          config.data.first_name = this.guestUser.first_name
          config.data.last_name = this.guestUser.last_name
          config.data.phone = this.guestUser.phone
          config.data.email = this.guestUser.email
        } else {
          this.setErrors(['Please enter your name, email, and phone number to checkout'])
          return
        }
      }
      const response = await this.sendRequest(config)
      this.setLoading(false)
      if (response.status === 'ok') {
        this.$router.push(`/order/confirm/${response.data.id}`)
      } else {
        this.setErrors([response.message])
      }
    },
    onApplePayButtonClicked() {
      try {
        if (!ApplePaySession) {
          this.setErrors('Uh-oh! We were unable to process your payment. Please review your payment method details & try again.')
          return
        }
      } catch (error) {
        this.setErrors('Uh-oh! We were unable to process your payment. Please review your payment method details & try again.')
        this.$utils.log(error)
        return
      }

      // Define ApplePayPaymentRequest
      const request = {
        "countryCode": "US",
        "currencyCode": "USD",
        "merchantCapabilities": [
          "supports3DS"
        ],
        "supportedNetworks": [
          "visa",
          "masterCard",
          "amex",
          "discover"
        ],
        "requiredBillingContactFields": [
          "postalAddress"
        ],
        "total": {
          "label": process.env.APP_NAME,
          "type": "final",
          "amount": this.total.toString()
        }
      };

      // Create ApplePaySession
      const session = new ApplePaySession(3, request);

      session.onvalidatemerchant = async event => {
        this.$utils.log('onvalidmerchant', event)
        // Call your own server to request a new merchant session.
        // const merchantSession = await validateMerchant();
        const merchantSession = await this.$axios({
          method: 'post',
          url: '/applepay',
          data: {
            channel: this.channel,
            validationURL: event.validationURL
          }
        }).then(res => {
          return res.data
        }).catch(err => {
          return {}
        })
        this.$utils.log(merchantSession)
        session.completeMerchantValidation(merchantSession);
      };

      session.onpaymentmethodselected = event => {
        const label = this.appInfo?.short_name ? this.appInfo.short_name : 'Apple Pay'
        this.$utils.log('onpaymentmethodselected', event)
        // Define ApplePayPaymentMethodUpdate based on the selected payment method.
        // No updates or errors are needed, pass an empty object.
        const update = {
          "newTotal": {
            "label": label,
            "type": "final",
            "amount": this.cart.totals.total.toString()
          }
        };
        session.completePaymentMethodSelection(update);
      };

      session.onshippingmethodselected = event => {
        this.$utils.log('onshippingmethodselected', event)
        // Define ApplePayShippingMethodUpdate based on the selected shipping method.
        // No updates or errors are needed, pass an empty object.
        const update = {};
        session.completeShippingMethodSelection(update);
      };

      session.onshippingcontactselected = event => {
        this.$utils.log('onshippingcontactselected', event)
        // Define ApplePayShippingContactUpdate based on the selected shipping contact.
        const update = {};
        session.completeShippingContactSelection(update);
      };

      session.onpaymentauthorized = event => {
        this.$utils.log('onpaymentauthorized', event)
        // Define ApplePayPaymentAuthorizationResult
        const result = {
          "status": ApplePaySession.STATUS_SUCCESS
        };
        session.completePayment(result);
        this.submitApplePay(event.payment)
      };

      session.oncouponcodechanged = event => {
        this.$utils.log('oncouponcodechanged', event)
        // Define ApplePayCouponCodeUpdate
        const newTotal = calculateNewTotal(event.couponCode);
        const newLineItems = calculateNewLineItems(event.couponCode);
        const newShippingMethods = calculateNewShippingMethods(event.couponCode);
        const errors = calculateErrors(event.couponCode);

        session.completeCouponCodeChange({
          newTotal: newTotal,
          newLineItems: newLineItems,
          newShippingMethods: newShippingMethods,
          errors: errors,
        });
      };

      session.oncancel = event => {
        this.$utils.log('oncancel', event)
        // Payment cancelled by WebKit
      };

      session.begin();
    },
    async setCartTotals() {
      const cart = this.cart ?? {};
      const totals = cart.totals ?? {};

      this.total = totals.total ?? 0;
      this.subtotal = totals.subtotal ?? 0;
      this.discount = totals.discount ?? 0;
      this.delivery = totals.handoff ?? 0;
      this.tax = totals.tax ?? 0;
      this.tip = totals.tip ?? 0;
      
      this.walletOptions = {
        options: {
          country: 'US',
          currency: 'usd',
          total: {
            label: 'Pay Now',
            amount: this.convertToSubUnits(this.total),
          },
          displayItems: [
            { label: 'Subtotal', amount: this.convertToSubUnits(this.subtotal) },
            { label: 'Discount', amount: this.convertToSubUnits(this.discount) },
            { label: 'Delivery Charge', amount: this.convertToSubUnits(this.delivery) },
            {
              label: 'Estimated Taxes & Fees',
              amount: this.convertToSubUnits(this.tax),
            },
            { label: 'Tip', amount: this.convertToSubUnits(this.tip) },
          ],
          requestPayerName: true,
        },
        style: {
          type: 'default',
          theme: 'dark',
          height: '48px',
        },
      }

      try {
        await this.digitalWallets.initialize(this.walletOptions);
        const canMakePayment = this.digitalWallets.canMakePayment;
        if (canMakePayment?.googlePay || canMakePayment?.applePay) {
          this.digitalWallets.mountButton(this.walletCallback);
          const paymentButton = this.digitalWallets.paymentButton;
          if (paymentButton) {
            // This causes endless loading if payment is cancelled
            // paymentButton.addEventListener("click", this.setLoadingCallback);
          } else {
            console.error('Payment button is not available.');
          }
        } else {
          console.warn('Neither Google Pay nor Apple Pay is available.');
        }
      } catch (error) {
        console.error('An error occurred during digital wallet initialization:', error);
      }

      this.$utils.log("SET CART TOTALS", this.cart)
      this.$utils.log("DIGITAL WALLET", this.digitalWallets)
    },
    setLoadingCallback(){
      this.setLoading(true)
    }
  },
  watch: {
    async cart(){
      if (this.ccFrame.name === 'OloPay' && ((this.applePay && this.hasApplePay?.show) || (this.googlePay && this.hasGooglePay?.show))) {
        await this.setCartTotals()
      }
    }
  }
}
