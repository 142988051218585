
import { mapState } from 'vuex';
import LogoGiftCard from '@pleinair/bigcolony-vue/components/_globals/vectors/LogoGiftCard.vue'
import PaymentRadioOn from '../globals/Icon/PaymentRadioOn.vue'
import PaymentRadioOff from '../globals/Icon/PaymentRadioOff.vue'

export default {
  name: 'StoredPayment',
  components: {
    LogoGiftCard,
    PaymentRadioOn,
    PaymentRadioOff
  },
  props: {
    account: {
      type: Object,
      default: () => {}
    },
    selected: {
      type: Boolean,
      default: false
    },
    onSelect: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    ...mapState([ 'cart' ]),
    balance() {
      return this.$utils.currency(this.account.balance)
    },
    remainingBalance() {
      let remaining = this.account.balance
      if(this.cart) {
        remaining = Math.max(this.account.balance - this.cart.totals.total, 0)
      }
      return this.$utils.currency(remaining)
    }
  },
  methods: {
    handleSelect() {
      this.onSelect(this.account)
    },
    getGiftCardLast4(description) {
      return description.match(/\d{4}$/)[0]
    }
  }
}
