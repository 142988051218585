
import DateSelect from './DateSelect.vue'
import Checkbox from './Checkbox.vue'
import InputDefault from './InputDefault.vue'
import Icon from '@pleinair/bigcolony-vue/components/common/Icon/index.vue'

export default {
  name: 'FormField',
  components: {
    DateSelect,
    Checkbox,
    InputDefault,
    Icon
  },
  props: {
    columns: {
      type: Number,
      default: 1
    },
    field: {
      type: Object,
      default: {
        name: '',
        label: '',
        type: 'text',
        value: '',
        valid: /.*?/,
        touched: false,
        error: null,
        errors: {
          invalid: 'Please enter a valid value.'
        }
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    validate: {
      type: Function,
      deafult: () => {}
    },
    passwordCriteria: {
      type: Array,
      default: () => {
        return [
          {
            name: 'length',
            valid: false,
            regex: '.{8,}'
          },
          {
            name: 'uppercase',
            valid: false,
            regex: '[A-Z]'
          },
          {
            name: 'lowercase',
            valid: false,
            regex: '[a-z]'
          },
          {
            name: 'number',
            valid: false,
            regex: '[0-9]'
          },
          {
            name: 'special',
            valid: false,
            regex: '[@$!%*?&]'
          },
        ]
      }
    },
  },
  data() {
    return {
      showPassword: false,
      internalField: null,
    }
  },
  computed: {
    toLink() {
      if(typeof window === 'undefined') {
        return this.field.to
      } else {
        return `${this.field.to}${window.location.search}`
      }
    },
    passwordIcon() {
      return this.showPassword ? 'hide-icon' : 'show-icon'
    },
    fieldType() {
      if(this.field.type === 'password') {
        return this.showPassword ? 'text' : 'password'
      } else {
        return this.field.type
      }
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword
    },
  },
}
